<template>
  <form
      @submit.prevent="submitQuotation"
      @keydown.enter.prevent="submitQuotation"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getQuotationNo" :lastSavedTime="lastSavedTime"
                 :enableLogicalBack="true"
                 back-to=" ">

      <!--      <AppButton color="indigo" label="Print Quotation" @click="printQuotation" :icon=true class="mr-2">-->
      <!--        <template v-slot:icon>-->
      <!--          <Icon iconType="print" class="mr-2"/>-->
      <!--        </template>-->
      <!--      </AppButton>-->

      <div v-for="(button, index) in workflowButtons" :key="index" class="mr-2">
        <AppButton :color="button.color" :label="button.label" @click="button.action" :icon="true">
          <template v-slot:icon>
            <Icon :iconType="button.iconType" class="mr-2"/>
          </template>
        </AppButton>
      </div>

    </page-header>

    <main class="pr-6 pl-6 pb-6  bg-background flex-1 overflow-y-scroll">

      <div class="flex flex-col bg-white md:flex-row items-center justify-between mt-6">
        <!-- Main Column 1 -->
        <div class=" w-full sm:w-full md:w-2/5 bg-white p-4 rounded md:rounded-none">
          <div class="w-full flex">
            <!-- Sub Column 1 of 1 -->
            <div class="w-2/5 p-1">
              <!-- Row 1 -->
              <div class="">
                <label class="uppercase font-semibold text-sm">Equipment Make</label>

                <Dropdown class="w-full bge-input  rounded" v-model="selectedEquipmentMake"
                          :options="equipmentMakeList"
                          optionLabel="name" placeholder="<Make>"
                />
              </div>
              <!-- Row 2 -->
              <div class="mt-4"> <!-- Added margin-top for alignment -->
                <label class="uppercase font-semibold text-sm">Equipment Model</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="quotation.product_code" type="text"/>
              </div>
            </div>

            <!-- Sub Column 1 of 2 -->
            <div class="w-3/5 p-1">
              <!-- Row 1 - Split into two parts -->
              <div class="flex">
                <div class="w-9/12 pr-1"> <!-- Adjusted to 80% -->
                  <label class="uppercase font-semibold text-sm">Serial NUMBER</label>
                  <input class="w-full bge-input bge-input-spacing rounded" v-model="quotation.serial_number"
                         type="text"/>
                </div>
                <div class="w-3/12 pl-1"> <!-- Adjusted to 20% -->
                  <label class="uppercase font-semibold text-sm">CONTRACT</label>
                  <input class="w-full bge-input bge-input-spacing rounded" type="text"/>
                </div>
              </div>
              <!-- Row 2 -->
              <div class="mt-4"> <!-- Added margin-top for alignment -->
                <label class="uppercase font-semibold text-sm">Description</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="quotation.name" type="text"/>
              </div>
            </div>
          </div>
        </div>

        <div class=" md:block">
          <div class="w-px bg-orange-500 md:h-32"></div>
        </div>

        <!-- Main Column 2 -->
        <div class=" w-full md:w-2/5 sm:w-full bg-white p-4 rounded  md:rounded-none">
          <div class="flex">
            <!-- Sub Column 2 of 1 -->
            <div class="w-1/2">
              <!-- Row 1 -->
              <div class="mb-2 p-1">
                <label class="uppercase font-semibold text-sm">Engineer</label>
                <StaffAutocomplete class="w-full" v-model="quotation.engineer" :forceSelection="true"/>
              </div>
              <!-- Row 2 -->
              <div class="p-1">
                <label class="uppercase font-semibold text-sm">Customer</label>
                <ContactAutocomplete class="w-full" v-model="quotation.customer"
                                     @contactSelected="handleContactSelected"
                                     @contactCleared="handleContactCleared" :forceSelection="true" :is_customer="true"/>
              </div>
            </div>
            <!-- Sub Column 2 of 2 -->
            <div class="w-1/2">              <!-- Row 1 -->

              <div class="mb-2">
                <div class="w-full">
                  <!-- Row 1 split into three parts -->
                  <div class="flex mb-2">
                    <div class="w-2/6 p-1">
                      <label class="uppercase font-semibold text-sm">Internal</label>
                      <Toggle class="w-full bge-input bge-input-spacing rounded"
                              labelPosition="left"
                              label=""
                              :checked="quotation.is_internal"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => {
                            quotation.is_internal = value;
                            if (value) quotation.is_external = !value;
                          }"
                      />
                    </div>
                    <div class="w-2/6 p-1">
                      <label class="uppercase font-semibold text-sm">External</label>
                      <Toggle class="w-full bge-input bge-input-spacing rounded"
                              labelPosition="left"
                              label=""
                              :checked="quotation.is_external"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => {
                            quotation.is_external = value;
                            if (value) quotation.is_internal = !value;
                          }"
                      />
                    </div>
                    <div class="w-2/6 p-1">
                      <label class="uppercase  font-semibold text-sm">Warranty</label>
                      <Toggle class="w-full bge-input bge-input-spacing rounded"
                              labelPosition="left"
                              label=""
                              :checked="quotation.is_warranty"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => (quotation.is_warranty = value)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Row 2 -->
              <div class="p-1">
                <label class="uppercase font-semibold text-sm">Agent</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="quotation.agent_name" type="text"/>
              </div>
            </div>
          </div>
        </div>

        <div class=" md:block">
          <div class="w-px bg-orange-500 md:h-32"></div>
        </div>

        <!-- Main Column 3 -->
        <div class="bg-white p-4 rounded md:rounded-none w-full md:w-1/5">
          <div class="flex flex-wrap">
            <!-- Sub Column 1 -->
            <div class="w-full md:w-8/12 p-1">
              <!-- Row 1 -->
              <div class="mb-2">
                <label class="block uppercase font-semibold text-sm">Job Start</label>
                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="quotation.job_start_date" :showIcon="true"
                />
              </div>
              <!-- Row 2 -->
              <div>
                <label class="block uppercase font-semibold text-sm">Job End</label>
                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="quotation.job_end_date" :showIcon="true"
                />
              </div>
            </div>

            <!-- Sub Column 2 (For alignment, mimics the structure of Sub Column 1) -->
            <div class="w-full md:w-4/12 p-1">
              <!-- Invisible placeholder for alignment -->
              <!-- Row 1 Placeholder (Maintains layout consistency) -->
              <div class="mb-2">
                <span class="block invisible uppercase font-semibold text-sm">Placeholder</span>

                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="quotation.job_start_time" :timeOnly="true"
                />
              </div>
              <!-- Row 2 Placeholder -->
              <div>
                <span class="block invisible uppercase font-semibold text-sm">Placeholder</span>

                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="quotation.job_end_time" :timeOnly="true"
                />
              </div>
            </div>
          </div>
        </div>


      </div>

      <!-- Job Description -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Job Description
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <Editor editorStyle="height: 120px" v-model="quotation.job_description" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

      <!--  Work To Be Carried out -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <!-- Header with Vertical Line -->
        <div class="flex items-center mb-5 mt-6">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
            Work To Be Carried out
          </h3>
          <hr class="flex-1 border-primary mx-4"/>

          <!-- Search Input and Button with Explicit Width -->
          <div class="flex ml-2 w-1/2 lg:w-1/3"> <!-- Adjusted the container width -->
            <input
                v-model="productCodeSearchInput"
                type="text"
                class="flex-grow rounded-l bge-input bge-input-spacing"
                placeholder="Search"
                @keydown.enter="triggerProductCodeSearch"
            />
            <button
                type="button"
                @click="triggerProductCodeSearch"
                class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <Icon iconType="search"/>
            </button>
          </div>
        </div>

        <!-- Two Columns for Controls (Responsive) -->
        <div class="flex flex-col md:flex-row">
          <!-- Left Column Control -->
          <div class="md:w-1/2 md:pr-2 mb-4 md:mb-0">
            <WorkCarried ref="workCarriedOutRef" :initialNotes="workCarriedOut"
                         @items-added="onNotesAdded"></WorkCarried>
          </div>

          <!-- Right Column Control -->
          <div class="md:w-1/2 md:pl-2">
            <DataTable :value="pre_set_text" :selection.sync="selectedPreSetText" dataKey="pre_text_id"
                       class="hide-header">
              <Column selectionMode="multiple" :styless="{width: '3rem'}" :exportable="false"></Column>
              <Column v-for="col in columnsWorkCarriedOut.filter(c => c.field !== 'pre_text_id')"
                      :key="col.field" :field="col.field" :header="col.header"></Column>

            </DataTable>
            <AppButton v-if=" selectedPreSetText && selectedPreSetText.length > 0" color="blue"
                       label="Copy To Work Carried Out"
                       :icon=true
                       class="m-2"
                       @click="copyToWorkCarriedOut">
              <template v-slot:icon>
                <Icon iconType="copy-plus" class="mr-2"/>
              </template>
            </AppButton>
          </div>
        </div>
      </div>

      <!-- Estimated Parts / Materials Required -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <!-- Header with Vertical Line -->
        <div class="flex items-center mb-5 mt-6">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
            Estimated Parts / Materials Used Required
          </h3>
          <hr class="flex-1 border-primary mx-4"/>

          <!-- Search Input and Button with Explicit Width -->
          <div class="flex ml-2 w-1/2 lg:w-1/3"> <!-- Adjusted the container width -->
            <input
                v-model="productSearchTermInput"
                type="text"
                class="flex-grow rounded-l bge-input bge-input-spacing"
                placeholder="Search"
                @keydown.enter="triggerProductSearch"
            />
            <button
                @click="triggerProductSearch"
                class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <Icon iconType="search"/>
            </button>
          </div>
        </div>

        <!-- Two Columns for Controls (Responsive) -->
        <div class="flex flex-col md:flex-row">
          <!-- Left Column Control -->
          <div class="md:w-1/2 md:pr-2 mb-4 md:mb-0">
            <PartsUsed ref="partsUsedRef" :initialProducts="partsUsed"></PartsUsed>
          </div>

          <!-- Right Column Control -->
          <div class="md:w-1/2 md:pl-2">
            <Products :searchTerm="productSearchTerm" @productSelected="handleProductSelected"></Products>
          </div>
        </div>
      </div>

      <!-- Manage Files -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Manage Files
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <ManageFiles :uploadRefId="quotation_id"></ManageFiles>
        </div>
      </div>


      <!-- Notes -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <label class="uppercase font-semibold text-sm">On quotation</label>
          <Editor editorStyle="height: 120px" v-model="quotation.notes" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>


      <!--Internal Engineer notes -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Internal Engineer notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>

          <label class="uppercase font-semibold text-sm">Not on quotation</label>
          <Editor editorStyle="height: 120px" v-model="quotation.internal_engineer_notes" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

    </main>


  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import WorkCarried from "@/views/HR/Quotation/Components/WorkCarried.vue";
import PartsUsed from "@/views/HR/Quotation/Components/PartsUsed.vue";
import Products from "@/views/HR/Quotation/Components/Products.vue";
import ManageFiles from "@/views/HR/Quotation/Components/ManageFiles.vue";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
import printJS from "print-js";

const Toggle = () => import("@/components/Toggle.vue");
export default {
  name: "ManageQuotation",
  mixins: [notificationMixin],
  components: {
    StaffAutocomplete,
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    AppButton,
    Icon,
    Toggle,
    WorkCarried,
    PartsUsed,
    Products,
    ManageFiles,
  },
  props: {
    equipment_id: String,
    repair_id: String,
    quotation_id: String,
    product_code: String,
    type: String,
  },
  data() {
    return {
      isBusy: false,
      productSearchTermInput: '',
      productSearchTerm: '',
      productCodeSearchInput: '',
      equipmentMakeList: [
        {id: "BOSTON", name: "BOSTON"},
        {id: "BRADBURY", name: "BRADBURY"},
        {id: "CONSUL", name: "CONSUL"},
        {id: "CRYPTON", name: "CRYPTON"},
        {id: "EAE", name: "EAE"},
        {id: "KISMET", name: "KISMET"},
        {id: "LAYCOCK", name: "LAYCOCK"},
        {id: "MAHLE", name: "MAHLE"},
        {id: "NUSBAUM", name: "NUSBAUM"},
        {id: "RAVAGLIOLI", name: "RAVAGLIOLI"},
        {id: "SNAP-ON / SUN", name: "SNAP-ON / SUN"},
        {id: "TECALEMIT", name: "TECALEMIT"},
        {id: "WERTHER", name: "WERTHER"}
      ],
      selectedEquipmentMake: null,
      quotation: {
        job_start_date: null,
        job_start_time: null,
        is_internal: true,
        is_external: false,
        is_warranty: false,
      },
      customer: {},
      columns: [
        {
          field: "equipment.product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '8%',
        },
        {
          field: "equipment.name",
          header: "Description",
          sortable: true,
          custom: false,
          width: '17%',
        },
        {
          field: "equipment.serial_number",
          header: "Serial No.",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "equipment.next_calibration",
          header: "Calibration",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-calibration",
          width: '10%',
        },
        {
          field: "equipment.next_service",
          header: "Service",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-service",
          width: '10%',
        },
        {
          field: "equipment.warranty_expiry",
          header: "Expiry",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-warranty",
          width: '10%',
        },
        {
          field: "scheduleDetail.is_install",
          header: "Install",
          custom: true,
          slotName: "job-slot-install",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_repair",
          header: "Repair",
          custom: true,
          slotName: "job-slot-repair",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_service",
          header: "Service",
          custom: true,
          slotName: "job-slot-service",
          width: '7%',
        },
        {
          field: "scheduleDetail.calibration",
          header: "Calibration",
          custom: true,
          slotName: "job-slot-calibration",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "Warranty",
          custom: true,
          slotName: "job-slot-warranty",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "",
          custom: true,
          slotName: "job-slot-sheet",
          width: '3%',
        },


      ],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },
      workCarriedOut: [],
      partsUsed: [],
      selectedPreSetText: null,
      pre_set_text: [],
      columnsWorkCarriedOut: [
        {field: 'pre_text_id', header: 'ID'},
        {field: 'text', header: 'Text'},
      ],
      refreshTimer: null,
      lastSavedTime: null,
    };
  },
  async mounted() {

    this.isBusy = true;
    if (this.quotation_id) {
      const quotation = await this.loadQuotationById();
      this.quotation = this.formatQuotationData(quotation);
    } else if (this.repair_id) {
      this.isBusy = true;
      await this.loadRepairByRepairId();
    } else if (this.equipment_id) {
      await this.loadEquipment(this.equipment_id);
    } else if (this.product_code) {
      await this.loadProduct(this.product_code);
    }

    this.setDefaultJobStartTime(this.quotation);

    if (!this.quotation.make) {
      const make = this.equipmentMakeList.find(make => make.id === 'BOSTON');
      if (make) {
        this.selectedEquipmentMake = make;
      }
    }
    this.updateLastSavedTime();
    this.refreshTimer = setInterval(this.updateLastSavedTime, 30000);

    this.isBusy = false;
  },

  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
  computed: {
    pageTitle() {
      return this.quotation_id ? "Update Quotation" : "Create Quotation";
    },
    getQuotationNo() {
      return this.quotation && this.quotation.quotation_no ? this.quotation.quotation_no : "";
    },

    workflowButtons() {
      switch (this.quotation.quotation_status) {
        case 'rejected':
          return [];
        case 'approved':
          return [
            {label: 'Go To Job Sheet', color: 'green', iconType: 'clipboard-text', action: this.goToJobSheet},
          ];
        case 'pending':
          return [
            {label: 'Close Quotation', color: 'red', iconType: 'close', action: this.closeQuotation},
            {
              label: 'Convert To Job Sheet',
              color: 'green',
              iconType: 'transition-right',
              action: this.convertToJobSheet
            },
            {label: 'Save Quotation', color: 'blue', iconType: 'save', action: this.saveQuotation},
          ];
        default:
          return [
            {label: 'Save Quotation', color: 'blue', iconType: 'save', action: this.saveQuotation},
          ];
      }
    },
  },
  methods: {
    updateLastSavedTime() {
      console.log("test");
      if (this.quotation.updated_at) {
        this.lastSavedTime = this.$moment.unix(this.quotation.updated_at).fromNow();
      } else if (this.quotation.created_at) {
        this.lastSavedTime = this.$moment.unix(this.quotation.created_at).fromNow();
      } else {
        this.lastSavedTime = null;
      }
    },
    jobType() {
      if (this.quotation.is_external) {
        return 'external';
      } else if (this.quotation.is_internal) {
        return 'internal';
      } else {
        return 'internal';
      }
    },
    setDefaultJobStartTime(formattedQuotation) {

      if (!formattedQuotation.job_start_date) {
        let currentMoment = this.$moment();
        formattedQuotation.job_start_date = currentMoment.toDate();
        formattedQuotation.job_end_date = currentMoment.toDate();
        formattedQuotation.job_start_time = new Date(`1970-01-01T${currentMoment.format('HH:mm:ss')}`);
      }
    },

    async loadRepairByRepairId() {
      try {

        const repairData = await this.RepairService.getRepairById(this.repair_id);
        if (!repairData) {
          return;
        }


        this.quotation = {
          ...this.quotation,
          product_code: repairData.product_code,
          product_id: repairData.product_id,
          serial_number: repairData.serial_number,
          name: repairData.product_description,
          equipment_id: repairData.equipment_id,
          customer: {
            name: repairData.customer ? repairData.customer.name : "",
            contact_id: repairData.customer ? repairData.customer.contact_id : ""
          },
        };

      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },
    formatQuotationData(quotation) {
      let formattedQuotation = JSON.parse(JSON.stringify(quotation));


      // Formatting job_start_date[
      if (formattedQuotation.job_start_date) {
        let startMoment = this.$moment.unix(formattedQuotation.job_start_date);
        formattedQuotation.job_start_date = startMoment.toDate();
        formattedQuotation.job_start_time = new Date(`1970-01-01T${startMoment.format('HH:mm:ss')}`);
      }

      // Formatting job_end_date
      if (formattedQuotation.job_end_date) {
        let endMoment = this.$moment.unix(formattedQuotation.job_end_date);
        formattedQuotation.job_end_date = endMoment.toDate();
        formattedQuotation.job_end_time = new Date(`1970-01-01T${endMoment.format('HH:mm:ss')}`);
      }

      if (formattedQuotation.equipment && formattedQuotation.equipment.service_agent) {
        formattedQuotation.agent_name = formattedQuotation.equipment.service_agent.name;
        formattedQuotation.product_code = formattedQuotation.equipment.product_code;
        formattedQuotation.product_type = formattedQuotation.equipment.product_type;
        formattedQuotation.serial_number = formattedQuotation.equipment.serial_number;
        formattedQuotation.name = formattedQuotation.equipment.name;
        formattedQuotation.equipment_id = formattedQuotation.equipment.equipment_id;
      } else if (formattedQuotation.product) {
        formattedQuotation.product_code = formattedQuotation.product.product_code;
        formattedQuotation.product_type = formattedQuotation.product.product_type;
        formattedQuotation.name = formattedQuotation.product.name;
      }

      if (formattedQuotation.engineer && formattedQuotation.engineer.first_name && formattedQuotation.engineer.last_name) {
        formattedQuotation.engineer.name = `${formattedQuotation.engineer.first_name} ${formattedQuotation.engineer.last_name}`
      }
      if (formattedQuotation.work_carried_out && formattedQuotation.work_carried_out.length > 0) {
        this.workCarriedOut = formattedQuotation.work_carried_out;
      }
      //
      if (formattedQuotation.parts_used && formattedQuotation.parts_used.length > 0) {
        this.partsUsed = formattedQuotation.parts_used;
      }


      if (formattedQuotation.make) {
        const make = this.equipmentMakeList.find(make => make.id === formattedQuotation.make);
        if (make) {
          this.selectedEquipmentMake = make;
        }
      }


      if (formattedQuotation.type != null) {
        switch (formattedQuotation.type) {
          case 'external':
            formattedQuotation.is_external = true;
            formattedQuotation.is_internal = false;
            break;
          case 'internal':
            formattedQuotation.is_internal = true;
            formattedQuotation.is_external = false;
            break;
          default:
            break;
        }
      }

      return formattedQuotation;
    },

    triggerProductSearch() {

      this.productSearchTerm = this.productSearchTermInput;
    },

    async triggerProductCodeSearch() {
      try {
        const product = await this.getProduct(this.productCodeSearchInput);

        this.pre_set_text = (product && Array.isArray(product.service_template)) ?
            product.service_template.reduce((acc, template) => {
              if (template.template_id && template.description) {
                acc.push({
                  pre_text_id: template.template_id,
                  text: template.description,
                });
              }
              return acc;
            }, []) : [];
      } catch (error) {
        this.notifyError("Error transforming product templates");
        console.error(error);
      }
    },

    async getProduct(searchTerm = '') {
      try {
        return await this.ProductService.getProductByCode(searchTerm);
      } catch (error) {
        this.notifyError("Error loading products");
        console.error(error);
      }
    },

    handleContactSelected: function (selectedCustomer) {

    },

    handleContactCleared(selectedContact) {

    },

    handleProductSelected(product) {
      this.$refs.partsUsedRef.addProducts([product]);
    },

    onNotesAdded() {
      this.selectedPreSetText = null;
    },
    copyToWorkCarriedOut() {
      this.$refs.workCarriedOutRef.addItems(this.selectedPreSetText);
    },

    async loadQuotationById() {
      try {
        const quotationData = await this.QuotationService.getQuotationById(this.quotation_id);

        if (!quotationData) {
          return;
        }
        return quotationData;

      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    async loadEquipment(equipmentId) {
      try {


        const equipmentData = await this.EquipmentService.getEquipmentItemById(equipmentId);

        if (!equipmentData) {
          return;
        }


        this.quotation = {
          ...this.quotation,
          product_code: equipmentData.product_code,
          product_type: equipmentData.product_type,
          serial_number: equipmentData.serial_number,
          name: equipmentData.name,
          agent_name: equipmentData.service_agent ? equipmentData.service_agent.name : "",
          equipment_id: equipmentData.equipment_id,
          customer: {
            name: equipmentData.owner ? equipmentData.owner.name : "",
            contact_id: equipmentData.owner ? equipmentData.owner.contact_id : ""
          },
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    async loadProduct(productCode) {
      try {

        const productData = await this.ProductService.getProductByCode(productCode);

        if (!productData) {
          return;
        }

        this.quotation = {
          ...this.quotation,
          product_code: productData.product_code,
          product_type: productData.type,
          name: productData.name,
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    combineAndFormatJobDatesToUnix(job) {

      let startDateTimeString = this.combineDateTimeString(job.job_start_date, job.job_start_time);
      let endDateTimeString = this.combineDateTimeString(job.job_end_date, job.job_end_time);

      let combinedJob = JSON.parse(JSON.stringify(job));
      combinedJob.job_start_date = this.$moment(startDateTimeString, 'DD-MM-YYYY HH:mm').unix();
      combinedJob.job_end_date = this.$moment(endDateTimeString, 'DD-MM-YYYY HH:mm').unix();

      return combinedJob;
    },

    combineDateTimeString(date, time) {

      let dateString = (typeof date === 'string') ? date : this.$moment(date).format('DD-MM-YYYY');
      let timeString = (typeof time === 'string') ? time : this.$moment(time).format('HH:mm');

      return `${dateString} ${timeString}`;
    },

    getAllQuotationDataToSave(quotation) {

      let formattedQuotation = this.combineAndFormatJobDatesToUnix(quotation);

      formattedQuotation.work_carried_out = this.getWorkCarriedData();
      formattedQuotation.parts_used = this.getPartsUsedData();

      if (this.selectedEquipmentMake && this.selectedEquipmentMake.id) {
        formattedQuotation.make = this.selectedEquipmentMake.id;
      }
      if (formattedQuotation.customer && formattedQuotation.customer.contact_id) {
        formattedQuotation.customer_id = formattedQuotation.customer.contact_id;
      }
      if (formattedQuotation.engineer && formattedQuotation.engineer.staff_id) {
        formattedQuotation.engineer_id = formattedQuotation.engineer.staff_id;
      }

      return formattedQuotation;
    },
    validateQuotation: function (quotation) {
      if (!quotation.engineer || Object.keys(quotation.engineer).length === 0) {
        this.notifyWarning("Quotation must include an engineer.");
        return false;
      }
      return true;
    },


    getWorkCarriedData() {
      if (this.$refs.workCarriedOutRef) {

        const allNotes = this.$refs.workCarriedOutRef.getCurrentNotes();
        const nonEmptyNotes = allNotes.filter(note => note.text && note.text.trim().length > 0);
        return nonEmptyNotes;
      }
    },

    getPartsUsedData() {
      return this.$refs.partsUsedRef ? this.$refs.partsUsedRef.getCurrentPartsUsed() : null;
    },

    goToJobSheet: async function () {
      if (this.quotation.job_sheet_id) {
        await this.$router.push({
          name: "engineer-job-sheet-edit",
          params: {
            job_sheet_id: this.quotation.job_sheet_id
          },
        });
      }
    },

    closeQuotation: async function () {
      this.quotation.quotation_status = 'rejected';
      await this.saveQuotation();
    },

    convertToJobSheet: async function () {
      if (!this.quotation_id) {
        this.notifyError("No quotation ID provided.");
        return;
      }

      try {
        await this.QuotationService.convertQuotationToJobSheet(this.quotation_id);
        this.notifySuccess("Quotation successfully converted to Job Sheet.");
        this.$router.go(-1);
      } catch (error) {
        this.notifyError("Couldn't convert Quotation to Job Sheet.");
        console.error(error);
      }
    },


    saveQuotation: async function () {
      this.isBusy = true;
      if (!this.validateQuotation(this.quotation)) {
        this.isBusy = false; // Reset the busy state as the operation is not proceeding
        return; // Exit the function early if validation fails
      }

      const formattedQuotation = this.getAllQuotationDataToSave(this.quotation);
      formattedQuotation.type = this.jobType();
      try {

        if (this.quotation_id) {
          await this.QuotationService.updateQuotation(
              this.quotation_id,
              formattedQuotation,
          );
          this.notifySuccess("Quotation updated successfully");
        } else {

          if (!this.repair_id) {
            return;
          }
          formattedQuotation.repair_id = this.repair_id;
          formattedQuotation.quotation_status = 'pending';

          await this.QuotationService.createQuotation(formattedQuotation);
          this.notifySuccess("Quotation created successfully");
        }

        if (this.quotation_id) {
          const quotation = await this.loadQuotationById();
          this.quotation = this.formatQuotationData(quotation);
          this.updateLastSavedTime();
          return;
        }
        this.$router.go(-1);

      } catch (error) {
        let errorMessage = this.incident_id
            ? "Couldn't update Quotation"
            : "Couldn't create Quotation";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitQuotation: async function (event) {
      event.preventDefault();
      event.stopPropagation();
    },

    replacePlaceholders: function (htmlTemplate, dynamicData) {
      let htmlContent = htmlTemplate;
      Object.keys(dynamicData).forEach(key => {
        const value = dynamicData[key];
        const regex = new RegExp(`{{${key}}}`, "g");
        htmlContent = htmlContent.replace(regex, value);
      });
      return htmlContent;
    },
    dynamicValues: function (jobSheet) {

      const date = jobSheet.job_start_date ? this.$moment(jobSheet.job_start_date).format('DD/MM/YYYY') : 'N/A';
      const timeStart = jobSheet.job_start_date ? this.$moment(jobSheet.job_start_date).format('HH:mm') : 'N/A';
      const timeFinish = jobSheet.job_end_date ? this.$moment(jobSheet.job_end_date).format('HH:mm') : 'N/A';


      const workCarriedOutText = jobSheet.work_carried_out && jobSheet.work_carried_out.map(item => item.text ? item.text : '').join("\n") || '';
      const partsUsedText = jobSheet.parts_used && jobSheet.parts_used.map(part => part.name ? `${part.name} - Qty: ${part.quantity}` : '').join("\n") || '';
      const loanEquipmentText = jobSheet.loan_equipment && jobSheet.loan_equipment.map(equipment => equipment.name ? `${equipment.name}, Serial Number: ${equipment.serial_number}, Action: ${equipment.action}` : '').join("\n") || '';

      const notes = (jobSheet.notes || '').replace(/<[^>]+>/g, '');
      const jobDescription = (jobSheet.job_description || '').replace(/<[^>]+>/g, '');

      const internalChecked = jobSheet.type === 'internal' ? 'checked' : '';
      const externalChecked = jobSheet.type === 'external' ? 'checked' : '';
      const warrantyChecked = jobSheet.is_warranty ? 'checked' : '';

      const checkboxesHtml = `
        <label><input type="checkbox" ${internalChecked}> INTERNAL</label>
        <label><input type="checkbox" ${externalChecked}> EXTERNAL</label>
        <label><input type="checkbox" ${warrantyChecked}> WARRANTY</label>
        `;

      let loanEquipmentHtml = '';
      if (jobSheet.loan_equipment) {
        jobSheet.loan_equipment.forEach(equipment => {
          loanEquipmentHtml += `
         <tr>
               <td>${equipment.serial_number || ''}</td>
               <td>${equipment.name || ''}</td>
               <td>${equipment.action || ''}</td>
         </tr>
        `;
        });
      }

      let workCarriedOutHtml = '';
      if (jobSheet.work_carried_out) {
        jobSheet.work_carried_out.forEach(item => {
          workCarriedOutHtml += `<li>${item.text || ''}</li>\n`;
        });
      }

      let partsUsedHtml = '';
      if (jobSheet.parts_used) {
        jobSheet.parts_used.forEach(part => {
          partsUsedHtml += `
        <tr>
            <td>${part.product_code || ''}</td>
            <td>${part.quantity || ''}</td>
            <td>${part.name || ''}</td>
        </tr>
        `;
        });
      }

      const equipmentMake = jobSheet.make || '';
      const equipmentModel = jobSheet.equipment && jobSheet.equipment.name ? jobSheet.equipment.name : '';
      const equipmentDescription = jobSheet.equipment && jobSheet.equipment.product_type ? jobSheet.equipment.product_type : '';
      const equipmentSerialNumber = jobSheet.equipment && jobSheet.equipment.serial_number ? jobSheet.equipment.serial_number : '';
      let customerPostCode = 'N/A';
      if (jobSheet.customer && jobSheet.customer.addresses) {
        const defaultAddress = jobSheet.customer.addresses.find(address => address.is_default);
        if (defaultAddress) customerPostCode = defaultAddress.postcode || 'N/A';
      }
      const engineerName = jobSheet.engineer ? `${jobSheet.engineer.first_name || ''} ${jobSheet.engineer.last_name || ''}` : '';

      return {
        date: date,
        timeStart: timeStart,
        timeFinish: timeFinish,
        jobSheetNo: jobSheet.job_sheet_no || '',
        workCarriedOut: workCarriedOutText,
        partsUsed: partsUsedText,
        loanEquipment: loanEquipmentText,
        customerName: jobSheet.customer ? jobSheet.customer.name : 'N/A',
        engineerName: engineerName,
        notes: notes,
        signingCustomerName: jobSheet.signing_customer_name || '',
        checkboxesHtml: checkboxesHtml,
        loanEquipmentHtml: loanEquipmentHtml,
        workCarriedOutHtml: workCarriedOutHtml,
        partsUsedHtml: partsUsedHtml,
        equipmentMake: equipmentMake,
        equipmentModel: equipmentModel,
        equipmentDescription: equipmentDescription,
        equipmentSerialNumber: equipmentSerialNumber,
        customerPostcode: customerPostCode,
        customerContract: '',
        descriptionOfWork: jobDescription,
        furtherDetails: '',
        scheduledJobNo: jobSheet.job_id || '-',
        documentsAttached: '',
        customerSignature: '',
      };
    },


    async loadHtmlTemplate() {
      try {
        const response = await fetch("/report_quotation.html");
        if (!response.ok) {
          throw new Error("Failed to load report template");
        }
        return await response.text();
      } catch (error) {
        console.error("Error loading HTML template:", error);
        throw error; // Or handle it as needed
      }
    },

    async generateCustomerSignatureHtml() {

      const customerFiles = await this.JobSheetService.getJobSheetDocuments(this.quotation.quotation_id, 'signature');
      let customerSignatureHtml = '';

      if (customerFiles && customerFiles.length > 0) {
        const customerSignatureUrl = customerFiles[0].access_url;

        customerSignatureHtml = `
      <div class="content-wrapped">
        <img src="${customerSignatureUrl}" alt="Customer Signature" class="signature-image" />
      </div>
    `;
      } else {
        customerSignatureHtml = '<div class="content-wrapped"></div>';
      }

      return customerSignatureHtml;
    },

    async printQuotation() {
      try {
        if (!this.quotation || !this.quotation.quotation_id) {
          this.notifyWarning("Please save the quotation before attempting to print.");
        }
        const htmlTemplate = await this.loadHtmlTemplate();
        const dynamicData = this.dynamicValues(this.quotation);

        dynamicData.customerSignature = await this.generateCustomerSignatureHtml();


        const printContent = this.replacePlaceholders(htmlTemplate, dynamicData);

        // Assuming printJS is available and configured
        printJS({
          printable: printContent,
          type: "raw-html",
        });
      } catch (error) {
        console.error("Error printing vehicle record:", error);
      }
    }
  }
}
;
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

</style>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>