<template>
  <div class="card">
    <DataTable :value="products" dataKey="product_id">
      <!-- Dynamic columns -->
      <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :headerStyle="{ width: col.width }"
      >
        <!-- Custom content based on column type -->
        <template v-if="col.type === 'editable'" #body="slotProps">
          <InputText
              v-if="editableRowId === slotProps.data.product_id"
              v-model.number="slotProps.data[col.field]"
              @input="updateAmount(slotProps.data)"
          />
          <span v-else>
          {{ col.field === 'quantity' ? slotProps.data[col.field] : slotProps.data[col.field].toFixed(2) }}
         </span>
        </template>

        <template v-else-if="col.type === 'amount'" #body="slotProps">
           <span class="text-right">
             £{{ slotProps.data.amount.toFixed(2) }}
           </span>
        </template>

        <template v-else-if="col.type === 'actions'" #body="slotProps">
          <div class="flex justify-end items-center">
            <AppButton v-if="editableRowId === slotProps.data.product_id" color="blue" label=""
                       :icon="true"
                       class="mr-1"
                       @click="saveRow(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editableRowId === slotProps.data.product_id && products.length !== 0" color="gray" label=""
                       :icon="true"
                       class="mr-1"
                       @click="cancelEdit(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
            <AppButton v-else color="blue" label=""
                       :icon="true"
                       class="mr-1"
                       @click="editRow(slotProps.data.product_id)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton color="red" label=""
                       :icon="true"
                       class="mr-1"
                       @click="deleteRow(slotProps.data.product_id)">
              <template v-slot:icon>
                <Icon iconType="delete"/>
              </template>
            </AppButton>
          </div>

        </template>
      </Column>
    </DataTable>
    <div class="total-amount flex justify-end border p-2">
      Total Value (ex VAT): £{{ totalAmount.toFixed(2) }}
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {
    AppButton,
    Icon
  },
  props: {
    initialProducts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      products: [],
      editableRowId: null,
      originalProductData: {},
      columns: [
        {field: 'product_code', header: 'Code', width: '10%'},
        {field: 'name', header: 'Name', width: '45%'},
        {field: 'quantity', header: 'Quantity', type: 'editable', width: '10%'},
        {field: 'price', header: 'Price', type: 'editable', width: '10%'},
        {field: 'amount', header: 'Amount', type: 'amount', width: '15%'},
        {header: '', type: 'actions', width: '10%'}
      ],
    };
  },
  watch: {
    initialProducts(newProducts) {
      this.products = newProducts.slice();
    },
  },
  mounted() {
    this.products = this.initialProducts.slice();
  },
  computed: {
    totalAmount() {
      return this.products.reduce((sum, product) => sum + product.amount, 0);
    }
  },
  methods: {

    getCurrentPartsUsed() {
      return this.products;
    },
    addProducts(productsArray) {

      productsArray.forEach(product => {
        if (this.isDuplicate(product)) {
          this.notifyWarning(`Product with ID ${product.product_id} is already added to the parts used.`);
        } else {

          const defaultQuantity = 1;

          const price = parseFloat(product.cost_price.value);

          const defaultAmount = (price * defaultQuantity);

          // Add the product with the additional properties
          this.products.push({
            ...product,
            price: price,
            quantity: defaultQuantity,
            amount: defaultAmount
          });
        }
      });
    },
    isDuplicate(newProduct) {
      return this.products.some(existingProduct => existingProduct.product_id === newProduct.product_id);
    },
    editRow(rowId) {
      const product = this.products.find(p => p.product_id === rowId);
      if (product) {
        this.originalProductData[rowId] = JSON.parse(JSON.stringify(product));
      }
      this.editableRowId = rowId;
    },
    saveRow(product) {
      if (!product.name || product.quantity === null || product.price === null) {
        this.notifyError("Fields cannot be empty.");
        return;
      }
      if (isNaN(product.quantity) || isNaN(product.price)) {
        this.notifyError("Quantity and Price must be numbers.");
        return;
      }
      product.amount = product.quantity * product.price;
      this.editableRowId = null;
      delete this.originalProductData[product.product_id];
    },
    cancelEdit(product) {

      if (product.isNew) {
        this.products = this.products.filter(p => p.product_id !== product.product_id);
      } else {
        const originalData = this.originalProductData[product.product_id];
        if (originalData) {
          const index = this.products.findIndex(p => p.product_id === product.product_id);
          if (index !== -1) {
            this.products.splice(index, 1, originalData);
          }
        }
      }
      this.editableRowId = null;
      delete this.originalProductData[product.product_id];
    },
    deleteRow(rowId) {
      this.products = this.products.filter(p => p.product_id !== rowId);
    },
    updateAmount(product) {
      product.amount = product.quantity * product.price;
    }
  }
};
</script>

<style>
.total-amount {
  margin-top: 10px;
  font-weight: bold;
}

</style>
