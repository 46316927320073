<template>
  <div class="card">
    <DataTable
        :value="sourceProducts"
        dataKey="product_id"
        :paginator="true"
        :rows="10"
        :totalRecords="sourceProducts.length"
        :lazy="false"
    >
      <!-- Dynamic columns -->
      <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :headerStyle="{ width: col.width }">
        <!-- Unified template for conditional rendering and default content -->
        <template #body="slotProps">
          <!-- Render price band dropdown if applicable -->
          <div v-if="col.field === 'price_band'">
            <Dropdown
                class="w-full"
                v-if="slotProps.data.price_bands && slotProps.data.price_bands.length > 0"
                :options="formatPriceBands(slotProps.data.price_bands)"
                v-model="slotProps.data.cost_price"
                optionLabel="label"
                placeholder="Select a Price Band"
            />
            <span v-else>No Price Bands</span>
          </div>

          <!-- Render action buttons if applicable -->
          <div v-else-if="col.custom">
            <AppButton color="blue" label=""
                       :icon="true"
                       class="mr-1"
                       @click="emitProduct(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="copy-plus"/>
              </template>
            </AppButton>
          </div>

          <!-- Default content rendering for other columns -->
          <div v-else>
            {{ slotProps.data[col.field] }}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>


<script>

import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {
    AppButton,
    Icon
  },
  mixins: [notificationMixin],
  props: {
    searchTerm: String,
  },
  data() {
    return {
      sourceProducts: [],
      columns: [
        {field: 'product_code', header: 'Code', width: '15%'},
        {field: 'name', header: 'Name', width: '65%'},
        {field: 'price_band', header: 'Price Band', width: '15%'},
        {header: 'Action', width: '5%', custom: true}
      ],
    };
  },

  watch: {
    searchTerm(newValue) {
      this.loadProducts(newValue);
    }
  },
  methods: {
    async loadProducts(searchTerm = '') {
      try {
        const products = await this.ProductService.getProductsByV2(searchTerm);
        this.sourceProducts = products;
      } catch (error) {
        this.notifyError("Error loading products");
        console.error(error);
      }
    },
    emitProduct(product) {
      this.$emit('productSelected', product);
    },
    formatPriceBands(bands) {
      return bands.map(band => ({
        value: band.price,
        label: `${band.description} - £${band.price.toFixed(2)}`
      }));
    }


  }
};
</script>

<style>
</style>
